body,
nav {
  background-color: #000000 !important;
}

body{
  display: flex;
  justify-content: center;
  align-items: center;

}
#root{
  background-color: #333;
  max-width: 425px;
  /* height: 100vh; */
}

.middle-container {
  background-color: #eee;
  border-radius: 12px;
  width: 300px;
}
.middle-container:hover {
  border: 1px solid #5957f9;
}
.dollar-div {
  background-color: #5957f9;
  padding: 12px;
  border-radius: 10px;
}
.round-div {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.dollar {
  font-size: 16px !important;
  color: #5957f9 !important;
  font-weight: bold !important;
}

.current-balance {
  font-size: 15px;
  color: #272727;
  font-weight: bold;
}
.amount {
  color: #5957f9;
  font-size: 16px;
  font-weight: bold;
}
.dollar-sign {
  font-size: 16px;
  color: #272727;
  font-weight: bold;
}

.top-container {
  display: flex;
  align-items: center;
}
.profile-image {
  border-radius: 10px;
  border: 2px solid #5957f9;
}
.name {
  font-size: 15px;
  font-weight: bold;
  color: #272727;
  position: relative;
  top: 8px;
}
.mail {
  font-size: 14px;
  color: grey;
  position: relative;
  top: 2px;
}
.ml-3 {
  margin-left: 30px;
}

.notifyBell {
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-nav {
  margin: 10px;
  font-size: 20px;
  cursor: pointer;
}
.menu-nav > span {
  margin-left: 10px;
}

.balanceCon {
  background-image: url(./assets/img/balance_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 20px;
}
.giftCon {
  background-image: url(./assets/img/gift_voucher.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 20px;
}

.carouselImage {
  height: 400px;
}
/* justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                height: "60vh",
                marginLeft: "20px",
                justify-content */
.teleimgCon {
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 400px;
  margin-left: 20px !important;
}

@media (max-width: 480px) {
  .carouselImage {
    height: 200px;
  }
  .teleimgCon {
    height: 170px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .carouselImage {
    height: 250px;
  }
  .teleimgCon {
    height: 230px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carouselImage {
    height: 300px;
  }
  .teleimgCon {
    height: 280px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .teleimgCon {
    height: 330px;
  }
  .carouselImage {
    height: 350px;
  }
}

@media (min-width: 1281px) {
  .carouselImage {
    height: 400px;
  }
}

.timeNo {
  background: rgb(243 243 243);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22);
  font-weight: 600;
  font-size: 20px;
}

.timerDots {
  font-weight: 600;
  font-size: 20px;
  padding: 10px;
}

.teleBg {
  background-image: url(./assets/img/teleBg2.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 20px;
}

.gameCate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gameCate img {
  width: 100px;
}
.gameCate p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.gameCate1 {
  margin: 10px;
}
.gameCate1 img {
  width: 100px;
}
.gameCate1 p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.gameCate2 {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameCate2 img {
  width: 100px;
}
.gameCate2 p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.gameCate4 {
}
.gameCate4 img {
  width: 100px;
}
.gameCate4 p {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}

.MuiBox-root {
  padding: 0px !important;
}

.carousel-status {
  display: none;
}

.winInfoCon {
  display: flex;
  justify-content: space-between;
  background: #4e4e4e;
  padding: 12px;
  border-radius: 10px;
}
.winInfoConSub1 {
  display:flex;
  align-items: center;
}
.winInfoConSub1 img{
  width: 46px!important;
  height: 46px;
  margin-right: 10px;
  border-radius: 7px;
}
.winInfoConSub1 div{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.stageCon{
  background-image: url('./assets/img/stage.png');
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 118px;
}

.stageCon{
  margin-top: 90px;
}
.stageConSub{
  display: flex;
  flex-direction: column;
  margin-bottom: 57px;
}
.stageCrownImg{
  width: 40px;
}
.stageFaceImg{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  
}
.stageRankImg{
  width: 60px;
}

.stageConSubText{
  background-color: #f6c768;
  padding: 6px;
  border-radius: 10px;
  margin: 0px;
}


.promoCon{
  background: url('./assets/img/bg/promotionbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.promoHead{
  background-color: #f6c768;
  color: #624100;
  padding: 9px;

}

.promoBody{
  color: #959595;
  font-weight: 600;
  background: #474747;
  padding: 10px;
}


.promoTab{
  background-color: #3F3F3F;
  color: #a9a9a9;
  padding: 10px 16px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;cursor: pointer;
}

.promoTabSub{
  font-weight: 700;
}

.actCon{
  background: #232323;
  padding: 10px;
  border-radius: 10px;
}


.downloadBtn span{
  font-size: 15px;
}


.transCon2{
  display: flex;
  align-items: center;
  padding: 22px;
  border-radius: 10px;
  height: 126px;
}


.transCon{
  display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.transCon img {
  width: 46px !important;
  height: 46px;
  margin-right: 10px;
  border-radius: 7px;
}

.transCon div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selectImg{
width: 55px;
height: 55px;
cursor: pointer;
}


.selectImg2 img{
width: 30px;
height: 30px;
}


/* .periodCon{
  background: url('./assets/img/bg/banner-4.png');
  background-repeat: no-repeat;
  background-size: cover;
} */
.periodCon{
  background: url('./assets/img/bg/banner-4.png');
  width: calc(100% - 0.69333rem);
  /* height: 2.66667rem; */
  margin: 0.48rem auto 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0.01333rem center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  color: #8f5206;
}


.ruleCon{
  position: absolute;
    top: 0.29333rem;
    left: 0.29333rem;
    height: 0.61333rem;
    width: 4rem;
    font-size: .29333rem;
    text-align: center;
    border: 0.01333rem solid #8F5206;
    border-radius: 0.8rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}


.tabImg{
width: 40px;
height: 40px;
}


.customInput{
border:1px solid #d9ac4f!important;
background-color: transparent!important;
color: #d9ac4f!important;
}

.customInput::placeholder{
color: #d9ac4f!important;
}



.timerbg {
  position: relative;
  display: flex;
  justify-content: space-around;
}

.timerbg__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../src/assets/img/bg/timerbg.png');
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.3);
  border-radius: 10px;
}

.timerbg h1{
  font-size: 128px !important;color: rgb(98, 65, 0);
  background: linear-gradient(180deg, rgba(217, 172, 79, 1) 35%, rgba(255, 208, 112, 1) 100%);
  padding: 10px;
  border-radius: 20px;
  font-weight: bold;
  z-index: 9;
}


.light-placeholder::placeholder {
  color: #999!important; 
}